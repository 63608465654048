.workoutCard {
  background-color: var(--light);
  border-width: 0;
  margin-bottom: var(--spacing-2);

  &:hover {
    background-color: var(--gray-200);
  }
}

.workoutCompleted,
.workoutCompleted:hover {
  background-color: rgba(40, 167, 69, 0.8);
  color: white;

  .subtitle {
    color: white;
  }

  .checkbox {
    border-color: white;

    svg {
      color: white;
      visibility: visible;
    }
  }
}

.checkbox {
  height: 1rem;
  width: 1rem;
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  border-radius: 100%;
  border: 2px solid var(--gray-500);

  &:hover {
    svg {
      visibility: visible;
    }
  }

  svg {
    position: absolute;
    top: -2px;
    left: -2px;
    color: var(--gray-500);
    visibility: hidden;
  }
}
