.sidebarVisible {
  padding-right: 400px;
}

.previewBadge {
  margin-left: 0.25rem;
  vertical-align: text-bottom;
}

.footer {
  background-color: var(--gray-100);
}
