.weekNumber {
  background-color: var(--light);
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: var(--border-color);
  border-width: 0 var(--border-width) var(--border-width) 0;
  border-style: solid;
  font-weight: bold;

  svg {
    margin-left: 0.5rem;
  }

  &.lastWeek {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-width: 0;
  }

  &.lastWeek.weekNumberSelected {
    border-bottom-left-radius: 0;
  }
}

.weekNumberSelected {
  background-color: var(--gray-200);
}
